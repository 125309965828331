@import url(https://fonts.googleapis.com/css?family=Dosis:500);
body {
  font-family: "Dosis", sans-serif;
  letter-spacing: 1.3px;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grid-container {
  display: grid;
  /* grid-template-columns: 240px 1fr; */
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    "sidenav header"
    "sidenav main"
    "sidenav footer";
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.main {
  grid-area: main;
  background-color: #c7c4c4ee;
}
.main h1 {
  margin: 20px;
}

@media only screen and (min-width: 46.875em) {
  /* Show the sidenav */
  .grid-container {
    grid-template-columns: 240px 1fr; /* Show the side nav for non-mobile screens */
    grid-template-areas:
      "sidenav header"
      "sidenav main"
      "sidenav footer";
  }
  .sidenav {
    display: flex;
    flex-direction: column;
  }
}
/* Medium-sized screen breakpoint (tablet, 1050px) */
@media only screen and (min-width: 65.625em) {
  /* Break out main cards into two columns */
  .main-cards {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
}

/* Non-mobile styles for side nav responsiveness, 750px breakpoint */
@media only screen and (min-width: 46.875em) {
  .sidenav {
    position: relative; /* Fixed position on mobile */
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  .sidenav__close-icon {
    visibility: hidden;
  }
}



.main-header {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  height: 150px;
  background-color: #e3e4e6;
  color: slategray;
}

.main-header {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #e3e4e6;
  color: slategray;
}



.menu-icon {
  position: fixed;
  display: flex;
  top: 15px;
  left: 10px;
  align-items: center;
  justify-content: center;
  /* border-radius: 50%; */
  z-index: 1;
  cursor: pointer;
  padding: 12px;
  /* background-color: #dadae3; */
}

.overviewcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #d3d3;
}
/*main-cards smaller device column to 1 instead of 2 with media query*/
.main-cards {
  -webkit-column-count: 1;
     -moz-column-count: 1;
          column-count: 1;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  margin: 20px;
}
/* 
.card {
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #d3d3;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 20px; 
}
*/

.card:first-child {
  display: flex;
}

.card:nth-child(2) {
  height: 200px;
}

.card:nth-child(3) {
  height: 265px;
}


/*media queries assisting in mobile friendly display*/

.style{
    border-radius:20px;

  }
.loader,
.loader:before,
.loader:after {
  background: #394263;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #394263;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


  
  .flex-outer,
  .flex-inner {
    list-style-type: none;
    padding: 0;
  }
  
  .flex-outer {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .flex-outer li,
  .flex-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
   
  }
  
  .flex-inner {
    padding: 0 8px;
    justify-content: space-between;  
  }
  
  .flex-outer > li:not(:last-child) {
    margin-bottom: 20px;
  }
  
  .flex-outer li label,
  .flex-outer li p {
    padding: 8px;
    /* font-weight: 300; */
    letter-spacing: .09em;
    text-transform: uppercase;
  }
  
  .flex-outer > li > label,
  .flex-outer li p {
    flex: 1 0 120px;
    max-width: 220px;
  }
  
  .flex-outer > li > label + *,
  .flex-inner {
    flex: 1 0 220px;
  }
  
  .flex-outer li p {
    margin: 0;
  }
  
  .flex-outer li input:not([type='checkbox']),
  .flex-outer li textarea {
    font-family: "Dosis", sans-serif;
  letter-spacing: 1.3px;
    /* font-weight: 300; */
    font-size: 15px;
    padding: 5px;
    border: none;
  }
  .select-category{
    font-family: "Dosis", sans-serif;
    letter-spacing: 1.3px;
      /* font-weight: 300; */
      font-size: 15px;
      padding: 5px;
      border: none;
  }
  
  .flex-outer li button {
    margin-left: auto;
    padding: 8px 16px;
    border: none;
    background: #333;
    color: #f2f2f2;
    text-transform: uppercase;
    letter-spacing: .09em;
    border-radius: 2px;
  }
  .error-input{
    color: red;
  }
  
 .input__alert{
    border-color:red
  }
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 24, 24, 0.5);
}
.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40%;
 
}

.sidenav {
    /* display: flex; */
    display: none;
    /* flex-direction: column; */
    grid-area: sidenav;
    background-color: #4484ce;
  }
  
  .sidenav{
  grid-area:sidenav;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  position:fixed;
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08);
  z-index:2;
  background-color: #394263;
  -webkit-transform: translateX(-245px);
          transform: translateX(-245px);
  -webkit-transition:all .6s ease-in-out;
  transition:all .6s ease-in-out;
  }
  /* The active class is toggled on hamburger and close icon clicks */
  .sidenav.active{
      -webkit-transform:translateX(0);
              transform:translateX(0)
  }
  
  /* Only visible on mobile screens */
  
  .sidenav__close-icon{
      position:absolute;
      visibility: visible;
      top:8px;
      right:12px;
      cursor: pointer;
      font-size: 20px;
      color:#ddd;
  
  }
  
  .sidenav__list {
    
    padding: 0;
    margin-top: 85px;
    list-style-type: none;
  }
  .sidenav__list-item {
   
    padding: 20px 20px 20px 40px;
    color: #ddd;
  }
  .sidenav__list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

/*media queries assisting in mobile friendly display*/
@media only screen and (min-width: 46.875em) {
    /* Show the sidenav */
    .grid-container {
      grid-template-columns: 240px 1fr; /* Show the side nav for non-mobile screens */
      grid-template-areas:
        "sidenav header"
        "sidenav main"
        "sidenav footer";
    }
    .sidenav {
      display: flex;
      flex-direction: column;
    }
  }
  /* Medium-sized screen breakpoint (tablet, 1050px) */
  @media only screen and (min-width: 65.625em) {
    /* Break out main cards into two columns */
    .main-cards {
      -webkit-column-count: 2;
         -moz-column-count: 2;
              column-count: 2;
    }
  }
  
  /* Non-mobile styles for side nav responsiveness, 750px breakpoint */
  @media only screen and (min-width: 46.875em) {
      .sidenav {
        position: relative; /* Fixed position on mobile */
        -webkit-transform: translateX(0);
                transform: translateX(0);
      }
    
      .sidenav__close-icon {
        visibility: hidden;
      }
    }
.header {
    grid-area: header;
    background-color: #f9cf00;
  }

  .header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

    .header__user{
      display: flex;
      justify-content:space-around;
      
    }
  
    .header-logout {
      margin-left: 0.25rem;
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  

    .search {
 
      display: flex;
      justify-content: space-between;
      
        
    }
    
    .searchTerm {
      width: 100%;
      border: 3px solid #f99500;
      border-right: none;
      padding: 5px;
      height: 20px;
      border-radius: 5px 0 0 5px;
      outline: none;
      color: #9DBFAF;
      font-size: 15px;
      font-weight: 200;
    }
    
    .searchTerm:focus{
      color: #f99500;
      
 
    }
    
    .searchButton {
      width: 40px;
      height: 36px;
      border: 1px solid #f99500;
      background: #f99500;
      text-align: center;
      color: #fff;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
      font-size: 20px;
      margin: 0;
    }
   
  
