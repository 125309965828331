.header {
    grid-area: header;
    background-color: #f9cf00;
  }

  .header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

    .header__user{
      display: flex;
      justify-content:space-around;
      
    }
  
    .header-logout {
      margin-left: 0.25rem;
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  

    .search {
 
      display: flex;
      justify-content: space-between;
      
        
    }
    
    .searchTerm {
      width: 100%;
      border: 3px solid #f99500;
      border-right: none;
      padding: 5px;
      height: 20px;
      border-radius: 5px 0 0 5px;
      outline: none;
      color: #9DBFAF;
      font-size: 15px;
      font-weight: 200;
    }
    
    .searchTerm:focus{
      color: #f99500;
      
 
    }
    
    .searchButton {
      width: 40px;
      height: 36px;
      border: 1px solid #f99500;
      background: #f99500;
      text-align: center;
      color: #fff;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
      font-size: 20px;
      margin: 0;
    }
   
  