

.main-header {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  height: 150px;
  background-color: #e3e4e6;
  color: slategray;
}

.main-header {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  height: fit-content;
  background-color: #e3e4e6;
  color: slategray;
}



.menu-icon {
  position: fixed;
  display: flex;
  top: 15px;
  left: 10px;
  align-items: center;
  justify-content: center;
  /* border-radius: 50%; */
  z-index: 1;
  cursor: pointer;
  padding: 12px;
  /* background-color: #dadae3; */
}

.overviewcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #d3d3;
}
/*main-cards smaller device column to 1 instead of 2 with media query*/
.main-cards {
  column-count: 1;
  column-gap: 20px;
  margin: 20px;
}
/* 
.card {
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #d3d3;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 20px; 
}
*/

.card:first-child {
  display: flex;
}

.card:nth-child(2) {
  height: 200px;
}

.card:nth-child(3) {
  height: 265px;
}


/*media queries assisting in mobile friendly display*/
