@import url("https://fonts.googleapis.com/css?family=Dosis:500");

body {
  font-family: "Dosis", sans-serif;
  letter-spacing: 1.3px;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grid-container {
  display: grid;
  /* grid-template-columns: 240px 1fr; */
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    "sidenav header"
    "sidenav main"
    "sidenav footer";
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.main {
  grid-area: main;
  background-color: #c7c4c4ee;
}
.main h1 {
  margin: 20px;
}

@media only screen and (min-width: 46.875em) {
  /* Show the sidenav */
  .grid-container {
    grid-template-columns: 240px 1fr; /* Show the side nav for non-mobile screens */
    grid-template-areas:
      "sidenav header"
      "sidenav main"
      "sidenav footer";
  }
  .sidenav {
    display: flex;
    flex-direction: column;
  }
}
/* Medium-sized screen breakpoint (tablet, 1050px) */
@media only screen and (min-width: 65.625em) {
  /* Break out main cards into two columns */
  .main-cards {
    column-count: 2;
  }
}

/* Non-mobile styles for side nav responsiveness, 750px breakpoint */
@media only screen and (min-width: 46.875em) {
  .sidenav {
    position: relative; /* Fixed position on mobile */
    transform: translateX(0);
  }

  .sidenav__close-icon {
    visibility: hidden;
  }
}
