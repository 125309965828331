.sidenav {
    /* display: flex; */
    display: none;
    /* flex-direction: column; */
    grid-area: sidenav;
    background-color: #4484ce;
  }
  
  .sidenav{
  grid-area:sidenav;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  position:fixed;
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08);
  z-index:2;
  background-color: #394263;
  transform: translateX(-245px);
  transition:all .6s ease-in-out;
  }
  /* The active class is toggled on hamburger and close icon clicks */
  .sidenav.active{
      transform:translateX(0)
  }
  
  /* Only visible on mobile screens */
  
  .sidenav__close-icon{
      position:absolute;
      visibility: visible;
      top:8px;
      right:12px;
      cursor: pointer;
      font-size: 20px;
      color:#ddd;
  
  }
  
  .sidenav__list {
    
    padding: 0;
    margin-top: 85px;
    list-style-type: none;
  }
  .sidenav__list-item {
   
    padding: 20px 20px 20px 40px;
    color: #ddd;
  }
  .sidenav__list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

/*media queries assisting in mobile friendly display*/
@media only screen and (min-width: 46.875em) {
    /* Show the sidenav */
    .grid-container {
      grid-template-columns: 240px 1fr; /* Show the side nav for non-mobile screens */
      grid-template-areas:
        "sidenav header"
        "sidenav main"
        "sidenav footer";
    }
    .sidenav {
      display: flex;
      flex-direction: column;
    }
  }
  /* Medium-sized screen breakpoint (tablet, 1050px) */
  @media only screen and (min-width: 65.625em) {
    /* Break out main cards into two columns */
    .main-cards {
      column-count: 2;
    }
  }
  
  /* Non-mobile styles for side nav responsiveness, 750px breakpoint */
  @media only screen and (min-width: 46.875em) {
      .sidenav {
        position: relative; /* Fixed position on mobile */
        transform: translateX(0);
      }
    
      .sidenav__close-icon {
        visibility: hidden;
      }
    }